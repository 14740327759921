import {
  useState,
  useEffect,
  createContext,
  startTransition,
  useLayoutEffect,
} from 'react'
import { ConfigProvider, notification, theme } from 'antd'

import { NOTIFICATION_PLACEMENT } from '~/utils/constants'

notification.config({
  placement: NOTIFICATION_PLACEMENT,
})

export const ThemeModeContext = createContext({
  themeMode: 'auto',
  setThemeMode: () => {},
})

const CustomConfigProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('themeMode') || matchBrowserPrefers('dark').matches
      ? 'dark'
      : 'light'
  )

  useEffect(() => {
    if (themeMode === 'auto') {
      const isDark = matchBrowserPrefers('dark').matches
      setThemeMode(isDark ? 'dark' : 'light')
    }
  }, [themeMode])

  useLayoutEffect(() => {
    const darkModeMediaQuery = matchMedia('(prefers-color-scheme: dark)')
    const lightModeMediaQuery = matchMedia('(prefers-color-scheme: light)')

    const darkModeListener = (e) => {
      if (e.matches) {
        safeStartTransition(() => setThemeMode('dark'))
      }
    }

    const lightModeListener = (e) => {
      if (e.matches) {
        safeStartTransition(() => setThemeMode('light'))
      }
    }

    darkModeMediaQuery.addEventListener('change', darkModeListener)
    lightModeMediaQuery.addEventListener('change', lightModeListener)

    return () => {
      darkModeMediaQuery.removeEventListener('change', darkModeListener)
      lightModeMediaQuery.removeEventListener('change', lightModeListener)
    }
  }, [])

  useLayoutEffect(() => {
    if (themeMode === 'dark') {
      document.body.classList.add('dark-mode')
    } else if (themeMode === 'light') {
      document.body.classList.remove('dark-mode')
    }
  }, [themeMode])

  return (
    <ThemeModeContext.Provider value={{ themeMode, setThemeMode }}>
      <ConfigProvider
        theme={{
          algorithm:
            themeMode === 'light'
              ? theme.defaultAlgorithm
              : theme.darkAlgorithm,
          token: {
            colorPrimary: themeMode === 'light' ? '#262c4a' : '#e3454b',
          },
        }}
      >
        {children}
      </ConfigProvider>
    </ThemeModeContext.Provider>
  )
}

export default CustomConfigProvider

/**
 * @param {Function} func
 */
export const safeStartTransition = (func) => {
  if (typeof startTransition === 'function') {
    startTransition(func)
  } else {
    func()
  }
}

/**
 * Check if the browser prefers a color scheme
 * @param {string} mode
 * @returns {boolean}
 */
export const matchBrowserPrefers = (mode) => {
  if (typeof window !== 'undefined') {
    return matchMedia && matchMedia(`(prefers-color-scheme: ${mode})`)
  }
  return { matches: false }
}
